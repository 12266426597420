import request from '@/utils/request'


export function batchEditByArea(data) {
  return request({
    url: "api/shop/areaPrice/batch",
    method: "post",
    data
  })
}

export function editByArea (data) {
  return request({
    url: 'api/shop/areaPrice',
    method: 'post',
    data
  })
}

export function delByArea (ids) {
  return request({
    url: 'api/shop/areaPrice/del',
    method: 'post',
    data: ids
  })
}

export function editByDistributor (data) {
  return request({
    url: 'api/shop/distributorPrice',
    method: 'post',
    data
  })
}

export function delByDistributor (ids) {
  return request({
    url: 'api/shop/distributorPrice/del',
    method: 'post',
    data: ids
  })
}