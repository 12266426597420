<template>
  <el-dialog :visible.sync="visible" :title="form && form.id ? '编辑零售价格' : '新增零售价格'" append-to-body width="640px" @closed="form = null">
    <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="84px" label-suffix=":" v-if="form">
      <div class="h c">
        <el-form-item label="行政区域" prop="provinceCode">
          <quick-select v-model="form.provinceCode" :label.sync="form.provinceName" url="api/regionalDict/province" value-field="code" placeholder="选择省份" filterable style="width: 180px;" @change="form.cityCode = null; form.districtCode = null;" />
        </el-form-item>
        <el-form-item label-width="12px" prop="cityCode" style="width: 180px;">
          <quick-select v-model="form.cityCode" :label.sync="form.cityName" :url="`api/regionalDict/parent/${form.provinceCode}`" value-field="code" placeholder="选择城市" filterable clearable v-if="form.provinceCode" style="width: 100%;" @change="form.districtCode = null" />
          <el-input disabled placeholder="请先选择省份" v-else />
        </el-form-item>
        <el-form-item label-width="12px" prop="districtCode" style="width: 180px;">
          <quick-select v-model="form.districtCode" :label.sync="form.districtName" :url="`api/regionalDict/parent/${form.cityCode}`" value-field="code" placeholder="选择地区" filterable clearable v-if="form.cityCode" style="width: 100%;" />
          <el-input disabled placeholder="请先选择城市" v-else />
        </el-form-item>
      </div>
      <el-form-item prop="price" label="零售价格">
        <price-input v-model="form.price" :min="0" style="width: 180px;" />
      </el-form-item>
      <!-- <el-form-item prop="enable" label="是否启用">
        <el-switch v-model="form.enable" />
      </el-form-item>-->
    </el-form>

    <div slot="footer">
      <el-button type="text" @click="visible = false">取消</el-button>
      <el-button :loading="saving" type="primary" @click="doSave">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { editByArea } from "@/api/retailPrice";

export default {
  data() {
    return {
      visible: false,
      form: null,
      saving: false,
      defaultProvinceCode: null,
      defaultCityCode: null,
      defaultDistrictCode: null,
      rules: {
        provinceCode: [{ required: true, message: "请选择省份" }],
        cityCode: [{ required: true, message: "请选择城市" }],
        price: [{ required: true, message: "请填写零售价" }]
      }
    };
  },
  computed: {
    ...mapGetters(["user"])
  },
  methods: {
    doSave() {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
          if (!this.form.id) {
            this.defaultProvinceCode = this.form.provinceCode;
            this.defaultCityCode = this.form.cityCode;
            this.defaultDistrictCode = this.form.districtCode;
          }
          this.saving = true;
          editByArea(this.form)
            .then(res => {
              this.$notify({
                title: "设置零售价成功",
                type: "success",
                duration: 2500
              });
              this.$emit("complete");
              this.visible = false;
            })
            .finally(_ => {
              this.saving = false;
            });
        });
    },
    resetForm(form, extend) {
      this.form =
        form ||
        Object.assign(
          {
            provinceCode: this.defaultProvinceCode,
            provinceName: "",
            cityCode: this.defaultCityCode,
            cityName: "",
            districtCode: this.defaultDistrictCode,
            districtName: "",
            price: 0
          },
          extend || {}
        );
      this.visible = true;
    }
  },
  mounted() {
    if (this.user) {
      this.defaultProvinceCode = this.user.provinceCode;
      this.defaultCityCode = this.user.cityCode;
    }
  }
};
</script>